.container {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  align-items: center;
}

.text {
  margin: 10px auto;
  text-align: justify;
}

.tableContainer {
  text-align: center;
  font-size: 20px;
  overflow-x: auto;
  max-width: 100%;
}

.table {
  border-collapse: collapse;
  position: relative;
  white-space: nowrap;
}
.td {
  box-sizing: border-box;
  padding: 10px;
  min-width: 100px;
  border: 2px solid var(--shadow-color);
}
.tdMagn {
  box-sizing: border-box;
  padding: 10px;
  min-width: 100px;
  background-color: #b9b9b940;
  border: 2px solid var(--shadow-color);
}

.tdS {
  position: sticky;
  left: -1px;
  box-sizing: border-box;
  padding: 10px;
  min-width: 80px;
  border: 2px solid lightgray;
  background-color: var(--first-color);
}
.tdSMagn {
  position: sticky;
  left: -1px;
  box-sizing: border-box;
  padding: 10px;
  min-width: 80px;
  border: 2px solid lightgray;
  background-color: var(--second-color);
  word-break: normal;
}

.tr1 {
  background-color: var(--second-color);
}

@media (max-width: 649px) {
  .tableContainer {
    text-align: center;
    overflow-x: scroll;
    font-size: 14px;
    width: 100%;
  }
  .tdS {
    min-width: 50px;
  }
  .tdSMagn {
    min-width: 50px;
    word-break: normal;
  }

  .td {
    min-width: 70px;
  }
  .tdMagn {
    min-width: 70px;
  }
}
