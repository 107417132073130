.photoes {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  max-width: 95%;
  margin: auto;
}

.block {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.inform p {
  font-weight: 300;
  font-style: normal;
  font-size: 1rem;
}

.image {
  position: relative;
  border-radius: 9%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  box-shadow: var(--second-color) -6px 6px;
  transition: ease 0.5s;
  overflow: hidden;
}

.image:hover {
  /* width: 20rem;
  height: 20rem; */
  overflow: hidden;
  position: relative;
  z-index: 10;
  transform: scale(1.8);
}

.singleProject img {
  width: 12rem;
  height: 12rem;
}

title {
  margin-bottom: 1rem 0 0;
}

.title p {
  font-weight: 100;
}
.title h3 {
  margin: 1rem;
}

.image a {
  cursor: pointer;
}

@media screen, print {
  .block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
  }

  .singleProject {
    margin: 20px 0;
  }
}
