.container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  list-style: none;
  align-items: center;
}

.link {
  cursor: pointer;
}

.link:hover {
  color: var(--second-color);
}

.container li {
  margin: 0 0.5rem;
}

.logo {
  width: 3rem;
  border-radius: 50%;
  cursor: default;
}
