:root {
  --first-color: #009cc5;
  --second-color: #99ff99;
  --shadow-color: lightgrey;
}

html {
  font-size: 20px;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: black;
}
a:hover {
  color: var(--first-color);
  text-decoration: underline;
}

@media (max-width: 650px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 650px) {
  html {
    font-size: 20px;
  }
}

#root {
  width: 100%;
}
