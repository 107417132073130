.wrapper {
  position: relative;
  height: 100%;
}

.main {
  background-color: var(--first-color);
  margin: 0 auto;
  max-width: 1080px;
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}
