.text {
  margin: auto;
  text-align: justify;
  width: 90%;
}

.inform {
  line-height: 150%;
}

.inform a {
  font-style: italic;
  color: var(--first-color);
}

.text ul p {
  font-size: 18;
}
