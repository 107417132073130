.start {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  margin: 0 3rem;
}

.inform {
  list-style: none;
  display: flex;
  flex-direction: column;
}

.inform p {
  font-weight: 200;
  padding-left: 2rem;
}

.inform li {
  margin: 0.5rem -2rem;
}

.container {
  margin: 0 auto;
  font-size: 1rem;
  max-width: 35rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.summary {
  list-style: none;
  text-align: left;
}

.summary li {
  margin: 1rem -2rem;
}

.icon {
  content: "";
  margin-right: 1rem;
  width: 1rem;
  height: 1rem;
  display: inline-grid;
  place-items: center;
  overflow: hidden;
}

.name {
  text-align: center;
}

.name h1 {
  margin-bottom: 0;
}
.name p {
  margin-top: 0.2rem;
}

.photo {
  width: 15rem;
  border: var(--second-color) solid 6px;
  box-shadow: var(--first-color) 0.7rem 0.3rem;
}

@media (max-width: 560px) {
  .container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    font-size: 15w;
  }

  .photo {
    width: 12rem;
    margin-right: 2rem;
  }
}

@media (max-width: 410px) {
  .container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    font-size: 15w;
  }

  .photo {
    width: 10rem;
    margin-right: 2rem;
  }
}
