.container {
  font-size: 1rem;
}

.variants {
  width: 4rem;
  height: 2rem;
  background-color: #fff;
  color: #000;
  font-size: 1rem;
  text-align: center;
  border-radius: 0.5rem;
  box-shadow: inset 0px 0px 0.1rem 0px #000;
}

@media (min-width: 650px) {
  .variants {
    width: 3.8rem;
    height: 1.9rem;
  }
}

@media (max-width: 650px) {
  .variants {
    width: 4.5rem;
    height: 2.5rem;
    margin-left: 2rem;
    margin-right: -2rem;
  }
}
