.container {
  margin: 0 auto;
  font-size: 1rem;
  max-width: 35rem;
  display: flex;
  align-items: center;
}

p {
  font-weight: 600;
  font-style: italic;
}

.summary {
  list-style: none;
  text-align: left;
}

.summary li {
  margin: 1rem -2rem;
}

.icon {
  content: "";
  margin-right: 1rem;
  width: 1rem;
  height: 1rem;
  display: inline-grid;
  place-items: center;
  overflow: hidden;
}

.name {
  text-align: center;
}

.name h1 {
  margin-bottom: 0;
}
.name p {
  margin-top: 0.2rem;
}

.photo {
  width: 12rem;
  margin-right: 3rem;
}

@media (max-width: 475px) {
  .container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .photo {
    width: 7rem;
  }
}
