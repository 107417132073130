.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.header {
  color: #fff;
  background-color: var(--first-color);
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #fff;
  position: fixed;
  width: 100%;
  height: 4rem;
}

@media (max-width: 540px) {
  .header {
    font-size: 10px;
  }
}

@media (max-width: 650px) {
  .header {
    font-size: 12px;
    padding: 0;
  }
}

@media (min-width: 650px) {
  .header {
    font-size: 15px;
  }
}

@media (min-width: 770px) {
  .header {
    font-size: 18px;
  }
}

@media (min-width: 950px) {
  .header {
    font-size: 20px;
  }
}
