.title {
  width: 20rem;
  background-color: #fff;
  border-radius: 0 0 1rem 0;
  text-align: right;
  font-size: 1.2rem;
  font-weight: 600;
  position: relative;
  z-index: 1;
  padding-right: 1.2rem;
  box-shadow: var(--shadow-color) -3px 6px;
}

.inform {
  background-color: #fff;
  padding: 1rem 0;
  width: 100%;
}

.container {
  padding-top: 4rem;
}
